import React, {useState, useRef} from 'react'
import * as classes from './Checkbox.module.scss'

const Checkbox = ({content, setIsCV, half}) => {
    const [checkboxState, setCheckboxState] = useState(false);
    const agreementCheckbox = useRef();
    const onChange = () => {
        setCheckboxState(checkboxState ? false : true );
        if(setIsCV) {
            setIsCV(checkboxState ? true : false );
        }
    }
    return (
        <label className={`${classes.checkbox__agreementCheckbox} ${half && classes.checkbox__half}`}>
            <div className={classes.checkbox__checkmark}><div className={checkboxState ? classes.checkbox__checkmarkActive : ""}></div></div>
            <input ref={agreementCheckbox} style={{display:'none'}} checked={checkboxState} type="checkbox" value={true} onChange={onChange}/>
            <p>{content}</p>
        </label>
    )
}

export default Checkbox