// extracted by mini-css-extract-plugin
export var appNameSection = "InputCheckbox-module--app-name-section--ELIta";
export var appText = "InputCheckbox-module--app-text--L9Ush";
export var appButton = "InputCheckbox-module--app-button--fkDle";
export var appButtonElement = "InputCheckbox-module--app-button-element--zUxdP";
export var appContainer = "InputCheckbox-module--app-container--cs9OL";
export var container = "InputCheckbox-module--container--ITI22";
export var appRow = "InputCheckbox-module--app-row--hUWnQ";
export var appCol = "InputCheckbox-module--app-col--OHzpc";
export var appCol1 = "InputCheckbox-module--app-col-1--8gD3G";
export var appCol2 = "InputCheckbox-module--app-col-2--SSwaj";
export var appCol3 = "InputCheckbox-module--app-col-3--6adCO";
export var appCol4 = "InputCheckbox-module--app-col-4--xZOYH";
export var appCol5 = "InputCheckbox-module--app-col-5--D05Me";
export var appCol6 = "InputCheckbox-module--app-col-6--xAmgL";
export var appCol7 = "InputCheckbox-module--app-col-7--ZOU9v";
export var appCol8 = "InputCheckbox-module--app-col-8--az1gK";
export var appCol9 = "InputCheckbox-module--app-col-9--yyFf+";
export var appCol10 = "InputCheckbox-module--app-col-10--SRbqV";
export var appCol11 = "InputCheckbox-module--app-col-11--W7mvp";
export var appCol12 = "InputCheckbox-module--app-col-12--MqwyC";
export var appColXl1 = "InputCheckbox-module--app-col-xl-1--TQAw6";
export var appColXl2 = "InputCheckbox-module--app-col-xl-2--0Neey";
export var appColXl3 = "InputCheckbox-module--app-col-xl-3--0Kgr6";
export var appColXl4 = "InputCheckbox-module--app-col-xl-4--cbVJ9";
export var appColXl5 = "InputCheckbox-module--app-col-xl-5--F53Tq";
export var appColXl6 = "InputCheckbox-module--app-col-xl-6--BOiUP";
export var appColXl7 = "InputCheckbox-module--app-col-xl-7--0BE3Z";
export var appColXl8 = "InputCheckbox-module--app-col-xl-8--tYpT+";
export var appColXl9 = "InputCheckbox-module--app-col-xl-9--Kfvp9";
export var appColXl10 = "InputCheckbox-module--app-col-xl-10--iXkDS";
export var appColXl11 = "InputCheckbox-module--app-col-xl-11--OfVj2";
export var appColXl12 = "InputCheckbox-module--app-col-xl-12--53NQm";
export var appColLg1 = "InputCheckbox-module--app-col-lg-1--I1brs";
export var appColLg2 = "InputCheckbox-module--app-col-lg-2--A2iIE";
export var appColLg3 = "InputCheckbox-module--app-col-lg-3--UzVY1";
export var appColLg4 = "InputCheckbox-module--app-col-lg-4--otkQ2";
export var appColLg5 = "InputCheckbox-module--app-col-lg-5--xaFWg";
export var appColLg6 = "InputCheckbox-module--app-col-lg-6--QRW-I";
export var appColLg7 = "InputCheckbox-module--app-col-lg-7--CDbc7";
export var appColLg8 = "InputCheckbox-module--app-col-lg-8--sQmUZ";
export var appColLg9 = "InputCheckbox-module--app-col-lg-9--V0IAy";
export var appColLg10 = "InputCheckbox-module--app-col-lg-10--SKRYg";
export var appColLg11 = "InputCheckbox-module--app-col-lg-11--HlhEa";
export var appColLg12 = "InputCheckbox-module--app-col-lg-12--L87SD";
export var appColMd1 = "InputCheckbox-module--app-col-md-1--zlAjI";
export var appColMd2 = "InputCheckbox-module--app-col-md-2--7tj3o";
export var appColMd3 = "InputCheckbox-module--app-col-md-3--99s-4";
export var appColMd4 = "InputCheckbox-module--app-col-md-4--4X8-I";
export var appColMd5 = "InputCheckbox-module--app-col-md-5--AHak6";
export var appColMd6 = "InputCheckbox-module--app-col-md-6--+a8Ns";
export var appColMd7 = "InputCheckbox-module--app-col-md-7--5bVbh";
export var appColMd8 = "InputCheckbox-module--app-col-md-8--xSC29";
export var appColMd9 = "InputCheckbox-module--app-col-md-9--nxAjK";
export var appColMd10 = "InputCheckbox-module--app-col-md-10--HxAyn";
export var appColMd11 = "InputCheckbox-module--app-col-md-11--ciyQo";
export var appColMd12 = "InputCheckbox-module--app-col-md-12--EBBXG";
export var appColSm1 = "InputCheckbox-module--app-col-sm-1--FAmA5";
export var appColSm2 = "InputCheckbox-module--app-col-sm-2--UpXi3";
export var appColSm3 = "InputCheckbox-module--app-col-sm-3--bS+q1";
export var appColSm4 = "InputCheckbox-module--app-col-sm-4--77iI5";
export var appColSm5 = "InputCheckbox-module--app-col-sm-5--HaMl3";
export var appColSm6 = "InputCheckbox-module--app-col-sm-6--oDuUj";
export var appColSm7 = "InputCheckbox-module--app-col-sm-7---kLEj";
export var appColSm8 = "InputCheckbox-module--app-col-sm-8--8D3Yi";
export var appColSm9 = "InputCheckbox-module--app-col-sm-9--FJNEG";
export var appColSm10 = "InputCheckbox-module--app-col-sm-10--VgGye";
export var appColSm11 = "InputCheckbox-module--app-col-sm-11--cUb9r";
export var appColSm12 = "InputCheckbox-module--app-col-sm-12--M0EDU";
export var appPseudo = "InputCheckbox-module--app-pseudo--Ahv14";
export var appFormLabel = "InputCheckbox-module--app-form-label--njLVq";
export var appFormLabelText = "InputCheckbox-module--app-form-label-text--fvuXK";
export var appFormInput = "InputCheckbox-module--app-form-input--plddo";
export var appFormLabelTextFocus = "InputCheckbox-module--app-form-label-text-focus--ECOI2";
export var appFormLabelError = "InputCheckbox-module--app-form-label-error--Jn-yP";
export var appFormError = "InputCheckbox-module--app-form-error--gIZSj";
export var inputCheckbox__error = "InputCheckbox-module--inputCheckbox__error--1nFUY";
export var inputCheckbox__agreementCheckbox = "InputCheckbox-module--inputCheckbox__agreementCheckbox--L68Ua";
export var inputCheckbox__half = "InputCheckbox-module--inputCheckbox__half--R6tpI";
export var inputCheckbox__checkmark = "InputCheckbox-module--inputCheckbox__checkmark--adeKi";
export var inputCheckbox__checkmarkActive = "InputCheckbox-module--inputCheckbox__checkmarkActive--OiisK";