// extracted by mini-css-extract-plugin
export var appNameSection = "FormMessage-module--app-name-section--x+61D";
export var appText = "FormMessage-module--app-text--gYm0N";
export var appButton = "FormMessage-module--app-button--M4RSG";
export var appButtonElement = "FormMessage-module--app-button-element--jMhXL";
export var appContainer = "FormMessage-module--app-container--1Hv96";
export var container = "FormMessage-module--container--ar2b4";
export var appRow = "FormMessage-module--app-row--EpbG1";
export var appCol = "FormMessage-module--app-col--0zBSn";
export var appCol1 = "FormMessage-module--app-col-1--5dsZA";
export var appCol2 = "FormMessage-module--app-col-2--F0hss";
export var appCol3 = "FormMessage-module--app-col-3--vFa4Y";
export var appCol4 = "FormMessage-module--app-col-4--fBvU-";
export var appCol5 = "FormMessage-module--app-col-5--MhMol";
export var appCol6 = "FormMessage-module--app-col-6--baRiG";
export var appCol7 = "FormMessage-module--app-col-7--MxZXP";
export var appCol8 = "FormMessage-module--app-col-8--j1CMw";
export var appCol9 = "FormMessage-module--app-col-9--sVDdd";
export var appCol10 = "FormMessage-module--app-col-10--QTH-f";
export var appCol11 = "FormMessage-module--app-col-11--1Wktt";
export var appCol12 = "FormMessage-module--app-col-12--9hxJl";
export var appColXl1 = "FormMessage-module--app-col-xl-1--CEdEQ";
export var appColXl2 = "FormMessage-module--app-col-xl-2--5Xdg8";
export var appColXl3 = "FormMessage-module--app-col-xl-3--vaj9o";
export var appColXl4 = "FormMessage-module--app-col-xl-4--SUkAk";
export var appColXl5 = "FormMessage-module--app-col-xl-5--4Ss4b";
export var appColXl6 = "FormMessage-module--app-col-xl-6--lXObR";
export var appColXl7 = "FormMessage-module--app-col-xl-7--kGeg5";
export var appColXl8 = "FormMessage-module--app-col-xl-8--E9bnY";
export var appColXl9 = "FormMessage-module--app-col-xl-9--dhXTC";
export var appColXl10 = "FormMessage-module--app-col-xl-10--ZZfjH";
export var appColXl11 = "FormMessage-module--app-col-xl-11--EGqD6";
export var appColXl12 = "FormMessage-module--app-col-xl-12--w8k2e";
export var appColLg1 = "FormMessage-module--app-col-lg-1--l5O-T";
export var appColLg2 = "FormMessage-module--app-col-lg-2--Neouk";
export var appColLg3 = "FormMessage-module--app-col-lg-3--wX3k7";
export var appColLg4 = "FormMessage-module--app-col-lg-4--jl4mh";
export var appColLg5 = "FormMessage-module--app-col-lg-5--PPvBo";
export var appColLg6 = "FormMessage-module--app-col-lg-6--8qMaM";
export var appColLg7 = "FormMessage-module--app-col-lg-7--IEcJ-";
export var appColLg8 = "FormMessage-module--app-col-lg-8--KSSVg";
export var appColLg9 = "FormMessage-module--app-col-lg-9--Nt8dT";
export var appColLg10 = "FormMessage-module--app-col-lg-10--j7Cd7";
export var appColLg11 = "FormMessage-module--app-col-lg-11--n+-Fp";
export var appColLg12 = "FormMessage-module--app-col-lg-12--o-eZS";
export var appColMd1 = "FormMessage-module--app-col-md-1--HVTen";
export var appColMd2 = "FormMessage-module--app-col-md-2--MyMsT";
export var appColMd3 = "FormMessage-module--app-col-md-3--tVaxk";
export var appColMd4 = "FormMessage-module--app-col-md-4--2WMt1";
export var appColMd5 = "FormMessage-module--app-col-md-5--deV3v";
export var appColMd6 = "FormMessage-module--app-col-md-6--i5AJu";
export var appColMd7 = "FormMessage-module--app-col-md-7--MnrH3";
export var appColMd8 = "FormMessage-module--app-col-md-8--l2tKC";
export var appColMd9 = "FormMessage-module--app-col-md-9--2r+2Y";
export var appColMd10 = "FormMessage-module--app-col-md-10--8cT-U";
export var appColMd11 = "FormMessage-module--app-col-md-11--eA2eN";
export var appColMd12 = "FormMessage-module--app-col-md-12--t6y5A";
export var appColSm1 = "FormMessage-module--app-col-sm-1--aFcN7";
export var appColSm2 = "FormMessage-module--app-col-sm-2--ZZ-Mn";
export var appColSm3 = "FormMessage-module--app-col-sm-3--bUz6z";
export var appColSm4 = "FormMessage-module--app-col-sm-4--CKd3s";
export var appColSm5 = "FormMessage-module--app-col-sm-5--2HbuF";
export var appColSm6 = "FormMessage-module--app-col-sm-6--KFhCL";
export var appColSm7 = "FormMessage-module--app-col-sm-7--LYM9c";
export var appColSm8 = "FormMessage-module--app-col-sm-8--os0lC";
export var appColSm9 = "FormMessage-module--app-col-sm-9--ToPiX";
export var appColSm10 = "FormMessage-module--app-col-sm-10--l7uPb";
export var appColSm11 = "FormMessage-module--app-col-sm-11--KdejE";
export var appColSm12 = "FormMessage-module--app-col-sm-12---LqsK";
export var appPseudo = "FormMessage-module--app-pseudo---RL+m";
export var appFormLabel = "FormMessage-module--app-form-label--4kPx9";
export var appFormLabelText = "FormMessage-module--app-form-label-text--ECQAB";
export var appFormInput = "FormMessage-module--app-form-input--cW2S6";
export var appFormLabelTextFocus = "FormMessage-module--app-form-label-text-focus--DZ6dN";
export var appFormLabelError = "FormMessage-module--app-form-label-error--UzCrx";
export var appFormError = "FormMessage-module--app-form-error--C9pGC";
export var formMessage = "FormMessage-module--formMessage--e9siQ";
export var formMessage__text = "FormMessage-module--formMessage__text--PDi2P";
export var formMessage__button = "FormMessage-module--formMessage__button--NbDz1";