import React, {useState, useEffect, useCallback} from 'react'
import * as classes from './InputSelect.module.scss'
import {useFormContext} from "react-hook-form"
import {StaticImage} from 'gatsby-plugin-image'
import Select from "react-select";

const styles = {
  container: () => {

  },
  control: (s) => ({
    ...s,
    background: 'none',
    border: 'none',
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: 'none',
    color: 'white',
    fontFamily: 'aktiv-grotesk-extended, sans-serif',
    fontWeight: 'bold',
    fontSize: '2rem',
    padding: 0,
    lineHeight: 1.3,
    marginLeft: 0,
  }),
  input: (s) => ({
    ...s,
    color: 'white',
    fontFamily: 'aktiv-grotesk-extended, sans-serif',
    fontWeight: 'bold',
    fontSize: '2rem',
    paddingBottom: 0,
    margin: 0,
    paddingTop: 0,
    "@media only screen and (min-width: 1600px)": {
      ...s["@media only screen and (min-width: 1600px)"],
      fontSize: '3.2rem',
    },
  }),
  valueContainer: (s) => ({
    ...s,
    paddingLeft: 0,
    padding: 0,
  }),
  placeholder: (s) => ({
    display: 'none'
  }),
  singleValue: (s) => ({
    ...s,
    padding: 0,
    color: 'white',
    fontFamily: 'aktiv-grotesk-extended, sans-serif',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.3,
    marginLeft: 0,
    "@media only screen and (min-width: 1600px)": {
      ...s["@media only screen and (min-width: 1600px)"],
      fontSize: '3.2rem',
      lineHeight: 1.5,
    },
  })
}

const InputSelect = ({name, label, requirements, values}) => {
  const {register, setValue, formState, watch} = useFormContext()
  const watcher = watch(name);
  const [errorState, setErrorState] = useState('')
  const [focusState, setFocusState] = useState('')

  const onBlur = useCallback(() => {
    if (!watcher) {
      setFocusState('')
    }
  }, [watcher])

  const onFocus = () => {
    setFocusState(classes.inputSelect__label_text_focus)
  }

  const onChange = useCallback((value) => {
    setValue(name, value.value)
  }, [])

  useEffect(() => {
    formState.errors[name] ? setErrorState(classes.inputSelect__labelError) : setErrorState('')
  }, [formState.errors[name]])
  return (
    <label className={`${classes.inputSelect__label} ${errorState}`}>
      <p className={`${classes.inputSelect__label_text} ${focusState}`}>{label}</p>
      <input type="hidden" {...register(name, requirements)}/>
      <Select options={values} styles={styles}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <div className={classes.inputSelect__icon_arrow_down}>
                    <StaticImage src="../../images/icon-arrow-down.svg" placeholder="blurred" quality={90} alt="Arrow icon"/>
                  </div>)
              }}
      />

      {formState.errors[name] && <span className={classes.inputSelect__error}>{formState.errors[name].message}</span>}
    </label>
  )
}

export default InputSelect
