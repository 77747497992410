const cookieConsentConfig = {
  current_lang: "pl",
  autoclear_cookies: true,
  page_scripts: true,
  languages: {
    pl: {
      consent_modal: {
        title: "Nasz serwis wykorzystuje pliki cookies",
        description:
          'W każdym momencie można dokonać zmiany ustawień przeglądarki internetowej w zakresie cookies. Więcej informacji o korzystaniu przez Administratora z plików cookie uzyskasz w <a href="https://brandmasterzy.pl/privacyPolicyCookies.pdf" target="_blank">Polityce prywatności</a>. <button type="button" data-cc="c-settings" class="cc-link"> Pozwól mi wybrać cookies.</button>',
        primary_btn: {
          text: "Akceptuj wszystkie",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Akceptuj tylko niezbędne",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Preferencje plików cookie",
        save_settings_btn: "Zapisz ustawienia",
        accept_all_btn: "Akceptuj wszystkie",
        reject_all_btn: "Odrzuć wszystkie",
        close_btn_label: "Zamknij",
        cookie_table_headers: [
          { col1: "Nazwa" },
          { col2: "Domena" },
          { col3: "Termin ważności" },
          { col4: "Opis" },
        ],
        blocks: [
          {
            title: "Wykorzystanie plików cookie",
            description:
              'Używamy plików cookie, aby zapewnić podstawowe funkcje witryny i poprawić komfort korzystania z Internetu. W dowolnym momencie możesz wyrazić zgodę lub nie, dla każdej kategorii. Aby uzyskać więcej informacji dotyczących plików cookie i innych danych wrażliwych, zapoznaj się z pełną <a href="https://brandmasterzy.pl/privacyPolicyCookies.pdf" target="_blank" class="cc-link">polityką prywatności</a>.',
          },
          {
            title: "Marketing",
            description:
              "Marketingowe pliki cookies pochodzą od zewnętrznych firm reklamowych i służą do zbierania informacji o stronach internetowych odwiedzanych przez użytkownika. Ich wykorzystanie odbywa się tylko za Twoją zgodą i tylko wtedy, gdy nie wyłączysz odpowiedniego pliku cookie.",
            toggle: {
              value: "ad_storage",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "Statystyki",
            description:
              "Statystyczne pliki cookies zbierają informacje o sposobie korzystania ze stron internetowych, aby poprawić ich atrakcyjność, zawartość i funkcjonalność. Ich wykorzystanie odbywa się tylko za Twoją zgodą i tylko wtedy, gdy nie wyłączysz odpowiedniego pliku cookie.",
            toggle: {
              value: "analytics_storage",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "Wymagane",
            description:
              "Pliki cookie niezbędne ze względów technicznych służą do technicznego działania strony internetowej i zapewnienia jej funkcjonalności. Ich wykorzystanie jest oparte na naszym uzasadnionym interesie, aby zapewnić technicznie bezbłędną stronę internetową. Możesz jednak zasadniczo wyłączyć obsługę plików cookie w swojej przeglądarce.",
            toggle: {
              value: "functionality_storage",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Więcej informacji",
            description:
              'W przypadku jakichkolwiek pytań dotyczących naszej polityki dotyczącej plików cookie i Twoich wyborów, prosimy o <a class="cc-link" href="https://www.bat.com.pl/group/sites/BAT_9Y2FAC.nsf/vwPagesWebLive/DO9T5G37?opendocument" target="_blank">kontakt</a>.',
          },
        ],
      },
    },
  },
  gui_options: {
    consent_modal: {
      layout: "cloud",
      position: "bottom center",
      transition: "slide",
      swap_buttons: false,
    },
    settings_modal: {
      layout: "box",
      position: "left",
      transition: "slide",
    },
  },
};

export default cookieConsentConfig;
