// extracted by mini-css-extract-plugin
export var appNameSection = "Footer-module--app-name-section--VA7zR";
export var appText = "Footer-module--app-text--kacx1";
export var appButton = "Footer-module--app-button--mPkkj";
export var appButtonElement = "Footer-module--app-button-element--4iBSm";
export var appContainer = "Footer-module--app-container--+2Wq7";
export var container = "Footer-module--container--cIk5r";
export var footer__container = "Footer-module--footer__container--RjrL-";
export var appRow = "Footer-module--app-row--CrC1I";
export var appCol = "Footer-module--app-col--z-ve0";
export var appCol1 = "Footer-module--app-col-1--bt6c-";
export var appCol2 = "Footer-module--app-col-2--M+JQh";
export var appCol3 = "Footer-module--app-col-3--lwAla";
export var appCol4 = "Footer-module--app-col-4--yndUM";
export var appCol5 = "Footer-module--app-col-5--IMAyb";
export var appCol6 = "Footer-module--app-col-6--Kpbj-";
export var appCol7 = "Footer-module--app-col-7--Z36xD";
export var appCol8 = "Footer-module--app-col-8--5fMrY";
export var appCol9 = "Footer-module--app-col-9--DLsdz";
export var appCol10 = "Footer-module--app-col-10--Casrz";
export var appCol11 = "Footer-module--app-col-11--u+g4U";
export var appCol12 = "Footer-module--app-col-12--G-Ksu";
export var appColXl1 = "Footer-module--app-col-xl-1--e5rCS";
export var appColXl2 = "Footer-module--app-col-xl-2--32yJm";
export var appColXl3 = "Footer-module--app-col-xl-3--ek3Yi";
export var appColXl4 = "Footer-module--app-col-xl-4--ec67R";
export var appColXl5 = "Footer-module--app-col-xl-5--f5p4a";
export var footer__firm = "Footer-module--footer__firm--cJSOE";
export var appColXl6 = "Footer-module--app-col-xl-6--zs8-z";
export var appColXl7 = "Footer-module--app-col-xl-7--ApCfL";
export var appColXl8 = "Footer-module--app-col-xl-8--KVWye";
export var appColXl9 = "Footer-module--app-col-xl-9--1DkPO";
export var appColXl10 = "Footer-module--app-col-xl-10--TH8MB";
export var appColXl11 = "Footer-module--app-col-xl-11--HOPC6";
export var appColXl12 = "Footer-module--app-col-xl-12--RmoYO";
export var appColLg1 = "Footer-module--app-col-lg-1--K6A0A";
export var appColLg2 = "Footer-module--app-col-lg-2--a1XCK";
export var appColLg3 = "Footer-module--app-col-lg-3--dpC+K";
export var appColLg4 = "Footer-module--app-col-lg-4--nKDEP";
export var appColLg5 = "Footer-module--app-col-lg-5--tLtw2";
export var appColLg6 = "Footer-module--app-col-lg-6--lDALo";
export var appColLg7 = "Footer-module--app-col-lg-7--8iFI-";
export var appColLg8 = "Footer-module--app-col-lg-8--4XzL8";
export var appColLg9 = "Footer-module--app-col-lg-9--WuEOf";
export var appColLg10 = "Footer-module--app-col-lg-10--k54vE";
export var appColLg11 = "Footer-module--app-col-lg-11--8EMwx";
export var appColLg12 = "Footer-module--app-col-lg-12--FvqMn";
export var appColMd1 = "Footer-module--app-col-md-1--q+is-";
export var appColMd2 = "Footer-module--app-col-md-2--d-uvE";
export var appColMd3 = "Footer-module--app-col-md-3--Q3dSJ";
export var appColMd4 = "Footer-module--app-col-md-4--+Mp7N";
export var appColMd5 = "Footer-module--app-col-md-5--51lX7";
export var appColMd6 = "Footer-module--app-col-md-6--r7Q9o";
export var appColMd7 = "Footer-module--app-col-md-7--VFjmy";
export var appColMd8 = "Footer-module--app-col-md-8--tSDqT";
export var appColMd9 = "Footer-module--app-col-md-9--N8V2l";
export var appColMd10 = "Footer-module--app-col-md-10--gWKyt";
export var appColMd11 = "Footer-module--app-col-md-11--sE+Cn";
export var appColMd12 = "Footer-module--app-col-md-12--nWGlL";
export var appColSm1 = "Footer-module--app-col-sm-1--o++gO";
export var appColSm2 = "Footer-module--app-col-sm-2--B1Y4I";
export var appColSm3 = "Footer-module--app-col-sm-3--DtIkh";
export var appColSm4 = "Footer-module--app-col-sm-4--Ntu0n";
export var appColSm5 = "Footer-module--app-col-sm-5--TjUkN";
export var appColSm6 = "Footer-module--app-col-sm-6--qpGid";
export var appColSm7 = "Footer-module--app-col-sm-7--vntJ8";
export var appColSm8 = "Footer-module--app-col-sm-8--D+OCg";
export var appColSm9 = "Footer-module--app-col-sm-9--PnrK6";
export var appColSm10 = "Footer-module--app-col-sm-10--yBVSt";
export var appColSm11 = "Footer-module--app-col-sm-11--0fetI";
export var appColSm12 = "Footer-module--app-col-sm-12--tATxO";
export var appPseudo = "Footer-module--app-pseudo--IsMuB";
export var appFormLabel = "Footer-module--app-form-label--cq0tM";
export var appFormLabelText = "Footer-module--app-form-label-text--Fhftw";
export var appFormInput = "Footer-module--app-form-input--4TnvB";
export var appFormLabelTextFocus = "Footer-module--app-form-label-text-focus--rX1T0";
export var appFormLabelError = "Footer-module--app-form-label-error--UOcT7";
export var appFormError = "Footer-module--app-form-error--eC3Tz";
export var footer = "Footer-module--footer--LZE0a";
export var footer__wrapper = "Footer-module--footer__wrapper--T945J";
export var footer__firms = "Footer-module--footer__firms--OoN7v";
export var footer__firmLogo = "Footer-module--footer__firmLogo--zLBPl";
export var footer__firmDesc = "Footer-module--footer__firmDesc--yjex3";
export var footer__copy = "Footer-module--footer__copy--g54mY";
export var footer__img = "Footer-module--footer__img--7x3qT";
export var footer__figure = "Footer-module--footer__figure--b8AzK";
export var footer__docs = "Footer-module--footer__docs--73b6O";
export var footer__doc = "Footer-module--footer__doc--30hKB";
export var footer__link = "Footer-module--footer__link--uvdJb";
export var footer__links = "Footer-module--footer__links--oXLIO";