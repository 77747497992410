import React, { useState, useEffect} from 'react'
import * as classes from './InputTextArea.module.scss'
import { useFormContext } from "react-hook-form";

const InputTextArea = ({name, label, type, requirements, honeypot}) => {
    const [errorState, setErrorState] = useState('')
    const [focusState, setFocusState] = useState('')
    const {register, formState}  = useFormContext()
    const inputType = type ? type : 'string'

    const inputFocus = () => {
        setFocusState(classes.InputTextArea__label_text_focus)
    }
    const inputBlur = (event) => {
        if (!event.target.value ) {
            setFocusState('')
        }
    }
    useEffect(() => {
        formState.errors[name] ? setErrorState(classes.InputTextArea__labelError) : setErrorState('')
    }, [formState.errors[name]])
    return (
        <label className={`${classes.InputTextArea__label} ${errorState}`} >
            <p className={`${classes.InputTextArea__label_text} ${focusState}`}>{label}</p>
            <textarea className={classes.InputTextArea__textarea} type={inputType} {...register(name, {...requirements})}  placeholder={'Opisz krótko swoje doświadczenie zawodowe (podaj przedziały czasowe poszczególnych miejsc)'}/>
            {formState.errors[name] && <span className={classes.InputTextArea__error} >{formState.errors[name].message}</span>}
        </label>
    )
}

export default InputTextArea
