import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, {useEffect, useState} from 'react'
import * as classes from './VerticalButton.module.scss'

const VerticalButton = ({stepsOffsetTop, lookingForOffsetTop}) => {
    const [visibilityState, setvisibilityState] = useState({opacity: 0, pointerEvents: 'none'})

    const scrollHabdler = () => {
        if (window.pageYOffset > lookingForOffsetTop && window.pageYOffset < stepsOffsetTop)  {
            setvisibilityState({opacity: 1, pointerEvents: 'painted'})
        } else {
            setvisibilityState({opacity: 0, pointerEvents: 'none'})
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHabdler);
        return () => {
            window.removeEventListener('scroll', scrollHabdler)
        }
    }, [stepsOffsetTop, lookingForOffsetTop])

    return (
        <Link style={visibilityState} to="/#applicationSteps" className={classes.verticalButton}>
            Sprawdź i aplikuj!
            <StaticImage className={classes.verticalButton__img} src='../../images/arrow-vertical-button.svg' alt='Arrow' placeholder='blurred' quality={90} layout='constrained' />
        </Link>
    )
}

export default VerticalButton