import React from 'react'
import * as classes from './InputSubmit.module.scss'

const InputSubmit = ({text, disabled}) => {
    return (
        <button className={classes.inputSubmit__submit} type="submit" disabled={disabled}>
            <svg width="133" height="70" viewBox="0 0 133 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_583_12990)">
                    <path d="M95.3166 69.4671L95.3145 71.8086L97.656 71.8086L99.2408 71.8086L101.577 71.8086L101.58 69.4724C101.592 61.0422 104.598 53.0059 109.873 47.1148C115.14 41.2322 122.221 37.9787 129.546 37.9659L131.882 37.9618L131.882 35.6265L131.882 33.8564L131.882 31.5144L129.54 31.517C120.377 31.5271 111.653 35.5989 105.262 42.737C98.8781 49.8666 95.3254 59.4834 95.3166 69.4671Z" fill="#FFCA3B" stroke="#FFCA3B" strokeWidth="4.67895"/>
                    <path d="M97.656 -2.3258L95.3144 -2.3258L95.3165 0.0157516C95.3254 9.9994 98.878 19.6162 105.262 26.7459C111.653 33.8839 120.377 37.9557 129.54 37.9658L131.882 37.9684L131.882 35.6263L131.882 33.8563L131.882 31.5209L129.546 31.5169C122.221 31.5041 115.14 28.2506 109.873 22.368C104.598 16.4769 101.592 8.44064 101.58 0.010393L101.577 -2.3258L99.2408 -2.3258L97.656 -2.3258Z" fill="#FFCA3B" stroke="#FFCA3B" strokeWidth="4.67895"/>
                    <path d="M129.542 37.9659L131.882 37.9659L131.882 35.6265L131.882 33.8564L131.882 31.517L129.542 31.517L3.55031 31.517L1.21083 31.517L1.21083 33.8564L1.21083 35.6265L1.21083 37.9659L3.55031 37.9659L129.542 37.9659Z" fill="#FFCA3B" stroke="#FFCA3B" strokeWidth="4.67895"/>
                </g>
            </svg>
            <p>{text}</p>
        </button>
    )
}

export default InputSubmit
