// extracted by mini-css-extract-plugin
export var appNameSection = "InputSubmit-module--app-name-section--r7JAH";
export var appText = "InputSubmit-module--app-text--B7TRp";
export var appButton = "InputSubmit-module--app-button--pw1pt";
export var appButtonElement = "InputSubmit-module--app-button-element--meT5T";
export var appContainer = "InputSubmit-module--app-container--wXqb1";
export var container = "InputSubmit-module--container--KcJk1";
export var appRow = "InputSubmit-module--app-row--hR7H-";
export var appCol = "InputSubmit-module--app-col--58Bo1";
export var appCol1 = "InputSubmit-module--app-col-1--dbdYC";
export var appCol2 = "InputSubmit-module--app-col-2--JYoy-";
export var appCol3 = "InputSubmit-module--app-col-3--ceadc";
export var appCol4 = "InputSubmit-module--app-col-4--F5nHl";
export var appCol5 = "InputSubmit-module--app-col-5--1XyXV";
export var appCol6 = "InputSubmit-module--app-col-6--BlEu1";
export var appCol7 = "InputSubmit-module--app-col-7--uk-+1";
export var appCol8 = "InputSubmit-module--app-col-8--0Z4uu";
export var appCol9 = "InputSubmit-module--app-col-9--2rgy5";
export var appCol10 = "InputSubmit-module--app-col-10--UuAyp";
export var appCol11 = "InputSubmit-module--app-col-11--BlOyE";
export var appCol12 = "InputSubmit-module--app-col-12--kufkw";
export var appColXl1 = "InputSubmit-module--app-col-xl-1--mI-Yt";
export var appColXl2 = "InputSubmit-module--app-col-xl-2--4KiRh";
export var appColXl3 = "InputSubmit-module--app-col-xl-3--nwzx+";
export var appColXl4 = "InputSubmit-module--app-col-xl-4--GksEm";
export var appColXl5 = "InputSubmit-module--app-col-xl-5--FN6-b";
export var appColXl6 = "InputSubmit-module--app-col-xl-6--IQnRP";
export var appColXl7 = "InputSubmit-module--app-col-xl-7--HVeHs";
export var appColXl8 = "InputSubmit-module--app-col-xl-8--QPe44";
export var appColXl9 = "InputSubmit-module--app-col-xl-9--MsEHH";
export var appColXl10 = "InputSubmit-module--app-col-xl-10--puvLh";
export var appColXl11 = "InputSubmit-module--app-col-xl-11--2LAD4";
export var appColXl12 = "InputSubmit-module--app-col-xl-12--FD4Ek";
export var appColLg1 = "InputSubmit-module--app-col-lg-1--7Li4Z";
export var appColLg2 = "InputSubmit-module--app-col-lg-2--HCMnP";
export var appColLg3 = "InputSubmit-module--app-col-lg-3--C099+";
export var appColLg4 = "InputSubmit-module--app-col-lg-4--Y9rqD";
export var appColLg5 = "InputSubmit-module--app-col-lg-5--wRc7Z";
export var appColLg6 = "InputSubmit-module--app-col-lg-6--DfPIf";
export var appColLg7 = "InputSubmit-module--app-col-lg-7--M-K0N";
export var appColLg8 = "InputSubmit-module--app-col-lg-8--VIMjU";
export var appColLg9 = "InputSubmit-module--app-col-lg-9--RzlN4";
export var appColLg10 = "InputSubmit-module--app-col-lg-10--hoxD+";
export var appColLg11 = "InputSubmit-module--app-col-lg-11--FzYp-";
export var appColLg12 = "InputSubmit-module--app-col-lg-12--S0elf";
export var appColMd1 = "InputSubmit-module--app-col-md-1--cpDnF";
export var appColMd2 = "InputSubmit-module--app-col-md-2--IozYh";
export var appColMd3 = "InputSubmit-module--app-col-md-3--w1tVe";
export var appColMd4 = "InputSubmit-module--app-col-md-4--uq6zv";
export var appColMd5 = "InputSubmit-module--app-col-md-5--6it4P";
export var appColMd6 = "InputSubmit-module--app-col-md-6--98Fdh";
export var appColMd7 = "InputSubmit-module--app-col-md-7--ta75H";
export var appColMd8 = "InputSubmit-module--app-col-md-8--9+eD6";
export var appColMd9 = "InputSubmit-module--app-col-md-9--BYTlB";
export var appColMd10 = "InputSubmit-module--app-col-md-10--XOf+n";
export var appColMd11 = "InputSubmit-module--app-col-md-11--4VWwB";
export var appColMd12 = "InputSubmit-module--app-col-md-12--q6wbZ";
export var appColSm1 = "InputSubmit-module--app-col-sm-1--rQMKA";
export var appColSm2 = "InputSubmit-module--app-col-sm-2--ZY4hk";
export var appColSm3 = "InputSubmit-module--app-col-sm-3--oP9ET";
export var appColSm4 = "InputSubmit-module--app-col-sm-4--KhNPp";
export var appColSm5 = "InputSubmit-module--app-col-sm-5--GIoZe";
export var appColSm6 = "InputSubmit-module--app-col-sm-6--1dfvI";
export var appColSm7 = "InputSubmit-module--app-col-sm-7---EPJW";
export var appColSm8 = "InputSubmit-module--app-col-sm-8--r56BL";
export var appColSm9 = "InputSubmit-module--app-col-sm-9--jt8UW";
export var appColSm10 = "InputSubmit-module--app-col-sm-10--SgMtK";
export var appColSm11 = "InputSubmit-module--app-col-sm-11--ZTVP-";
export var appColSm12 = "InputSubmit-module--app-col-sm-12--mzwoH";
export var appPseudo = "InputSubmit-module--app-pseudo--ml3OO";
export var appFormLabel = "InputSubmit-module--app-form-label--5gv7R";
export var appFormLabelText = "InputSubmit-module--app-form-label-text--nx0Yr";
export var appFormInput = "InputSubmit-module--app-form-input--JRQpp";
export var appFormLabelTextFocus = "InputSubmit-module--app-form-label-text-focus--KU3UF";
export var appFormLabelError = "InputSubmit-module--app-form-label-error--8IGGS";
export var appFormError = "InputSubmit-module--app-form-error--VDsPf";
export var inputSubmit__submit = "InputSubmit-module--inputSubmit__submit--KrbS-";