import React, {useEffect} from "react";
import config from "./CookieConsent.config";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/dist/cookieconsent";
import './CookieConsent.module.scss';

export default function CookieConsent() {
  const onFirstAction = React.useCallback(() => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({ event: "consentUpdated" });
  }, []);
  const onAccept = React.useCallback(() => {}, []);
  const onChange = React.useCallback(() => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({ event: "consentUpdated" });
  }, []);

  useEffect(() => {
    if (!window || !window.initCookieConsent) {
      return;
    }
    const cc = window.initCookieConsent();
    console.log(cc);
    cc.run({
      ...config,
      onChange,
      onAccept,
      onFirstAction,
    });

    const openCookieButton = document.getElementById("cc-settings");
    if (openCookieButton) {
      openCookieButton.addEventListener("click", () => {
        cc.showSettings();
      });
    }
  }, []);
  return <></>;
};
