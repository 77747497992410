// extracted by mini-css-extract-plugin
export var appNameSection = "VerticalButton-module--app-name-section--zcKER";
export var appText = "VerticalButton-module--app-text--GKYcZ";
export var appButton = "VerticalButton-module--app-button--5wPLK";
export var appButtonElement = "VerticalButton-module--app-button-element--3OLtT";
export var appContainer = "VerticalButton-module--app-container--Qfrhv";
export var container = "VerticalButton-module--container--jv4Pv";
export var appRow = "VerticalButton-module--app-row--o8l-A";
export var appCol = "VerticalButton-module--app-col--PApLH";
export var appCol1 = "VerticalButton-module--app-col-1--IwJrb";
export var appCol2 = "VerticalButton-module--app-col-2--BidF6";
export var appCol3 = "VerticalButton-module--app-col-3--40SHP";
export var appCol4 = "VerticalButton-module--app-col-4--lE3qp";
export var appCol5 = "VerticalButton-module--app-col-5--Hw0fS";
export var appCol6 = "VerticalButton-module--app-col-6--f+EPT";
export var appCol7 = "VerticalButton-module--app-col-7--xjSBa";
export var appCol8 = "VerticalButton-module--app-col-8--1vEWz";
export var appCol9 = "VerticalButton-module--app-col-9--udDx7";
export var appCol10 = "VerticalButton-module--app-col-10--vvhFu";
export var appCol11 = "VerticalButton-module--app-col-11--P3Ynx";
export var appCol12 = "VerticalButton-module--app-col-12--RT6Ke";
export var appColXl1 = "VerticalButton-module--app-col-xl-1--oXb5X";
export var appColXl2 = "VerticalButton-module--app-col-xl-2--j12dk";
export var appColXl3 = "VerticalButton-module--app-col-xl-3--gmT5R";
export var appColXl4 = "VerticalButton-module--app-col-xl-4--MoZnE";
export var appColXl5 = "VerticalButton-module--app-col-xl-5--6dbFJ";
export var appColXl6 = "VerticalButton-module--app-col-xl-6--isMeb";
export var appColXl7 = "VerticalButton-module--app-col-xl-7--dNXzF";
export var appColXl8 = "VerticalButton-module--app-col-xl-8--WbUC7";
export var appColXl9 = "VerticalButton-module--app-col-xl-9--9lvvF";
export var appColXl10 = "VerticalButton-module--app-col-xl-10--XymVv";
export var appColXl11 = "VerticalButton-module--app-col-xl-11--Dpwkr";
export var appColXl12 = "VerticalButton-module--app-col-xl-12--z1vgB";
export var appColLg1 = "VerticalButton-module--app-col-lg-1--TNtqg";
export var appColLg2 = "VerticalButton-module--app-col-lg-2---9AF8";
export var appColLg3 = "VerticalButton-module--app-col-lg-3--MpXJU";
export var appColLg4 = "VerticalButton-module--app-col-lg-4--5-WoK";
export var appColLg5 = "VerticalButton-module--app-col-lg-5--W-lDJ";
export var appColLg6 = "VerticalButton-module--app-col-lg-6--kzUAd";
export var appColLg7 = "VerticalButton-module--app-col-lg-7--mdJJ-";
export var appColLg8 = "VerticalButton-module--app-col-lg-8--3sMWf";
export var appColLg9 = "VerticalButton-module--app-col-lg-9--gglD-";
export var appColLg10 = "VerticalButton-module--app-col-lg-10--iVpIp";
export var appColLg11 = "VerticalButton-module--app-col-lg-11--xZK5b";
export var appColLg12 = "VerticalButton-module--app-col-lg-12--ftJgP";
export var appColMd1 = "VerticalButton-module--app-col-md-1--Aa+Al";
export var appColMd2 = "VerticalButton-module--app-col-md-2--Q3LBq";
export var appColMd3 = "VerticalButton-module--app-col-md-3--T04IG";
export var appColMd4 = "VerticalButton-module--app-col-md-4--pt49b";
export var appColMd5 = "VerticalButton-module--app-col-md-5--WitKd";
export var appColMd6 = "VerticalButton-module--app-col-md-6--H3IxR";
export var appColMd7 = "VerticalButton-module--app-col-md-7--emwCh";
export var appColMd8 = "VerticalButton-module--app-col-md-8--egVoh";
export var appColMd9 = "VerticalButton-module--app-col-md-9--4uZH2";
export var appColMd10 = "VerticalButton-module--app-col-md-10--Use6m";
export var appColMd11 = "VerticalButton-module--app-col-md-11--WBvAA";
export var appColMd12 = "VerticalButton-module--app-col-md-12--QXQDy";
export var appColSm1 = "VerticalButton-module--app-col-sm-1--5iOWu";
export var appColSm2 = "VerticalButton-module--app-col-sm-2--aWLyE";
export var appColSm3 = "VerticalButton-module--app-col-sm-3--mF+MA";
export var appColSm4 = "VerticalButton-module--app-col-sm-4--Zugqe";
export var appColSm5 = "VerticalButton-module--app-col-sm-5--9fQqY";
export var appColSm6 = "VerticalButton-module--app-col-sm-6--hqdVh";
export var appColSm7 = "VerticalButton-module--app-col-sm-7--i37eL";
export var appColSm8 = "VerticalButton-module--app-col-sm-8--HU0S7";
export var appColSm9 = "VerticalButton-module--app-col-sm-9--2oEqe";
export var appColSm10 = "VerticalButton-module--app-col-sm-10---xlvF";
export var appColSm11 = "VerticalButton-module--app-col-sm-11--iRHxs";
export var appColSm12 = "VerticalButton-module--app-col-sm-12--NoBcN";
export var appPseudo = "VerticalButton-module--app-pseudo--Y1T3y";
export var appFormLabel = "VerticalButton-module--app-form-label--jWJ9C";
export var appFormLabelText = "VerticalButton-module--app-form-label-text--3w--v";
export var appFormInput = "VerticalButton-module--app-form-input--H+Uqm";
export var appFormLabelTextFocus = "VerticalButton-module--app-form-label-text-focus--elxsX";
export var appFormLabelError = "VerticalButton-module--app-form-label-error--yGsXy";
export var appFormError = "VerticalButton-module--app-form-error--XQNf5";
export var verticalButton = "VerticalButton-module--verticalButton--TgM+j";
export var verticalButton__img = "VerticalButton-module--verticalButton__img--d6LSz";