import React, {useEffect, useRef} from 'react';
import * as classes from './FormMessage.module.scss'

const FormMessage = ({message, succes,reloadPage, sendMoreApplication}) => {
  const section = useRef(null);

  useEffect(() => {
    window.scrollTo(0, section.current.offsetTop - 50);
  }, []);

  return (
    message === succes
        ?<div ref={section} className={classes.formMessage}>
            <p className={classes.formMessage__text}>Dziękujemy za wysłanie zgłoszenia.<br /> Wkrótce otrzymasz odpowiedź.</p>
            <button className={classes.formMessage__button} onClick={sendMoreApplication}>Wyślij kolejne zgłoszenie</button>
        </div>
        :<div ref={section} className={classes.formMessage}>
            <p className={classes.formMessage__text}>Przepraszamy. Coś poszło nie tak.<br /> Odśwież stronę i wyślij formularz ponownie.</p>
            <button className={classes.formMessage__button} onClick={reloadPage}>Odśwież teraz</button>
        </div>
  )
};

export default FormMessage;