import React, {useState, useRef} from 'react'
import * as classes from './InputCheckbox.module.scss'
import { useFormContext } from "react-hook-form"

const InputCheckbox = ({name, requirements, content, termsLink, half}) => {
    const {register, formState}  = useFormContext()
    const [checkboxState, setCheckboxState] = useState(false);
    const agreementCheckbox = useRef();
    const checkbox = () => {
        setCheckboxState(checkboxState ? false : true );
    }
    return (
        <label className={`${classes.inputCheckbox__agreementCheckbox} ${half && classes.inputCheckbox__half}`} onChange={checkbox}>
            <div className={classes.inputCheckbox__checkmark}><div className={checkboxState ? classes.inputCheckbox__checkmarkActive : ""}></div></div>
            <input ref={agreementCheckbox} style={{display:'none'}} checked={checkboxState} type="checkbox" value={true} {...register(name, {...requirements, })}/>
            <p>
               {content}{termsLink}
            </p>
            {formState.errors[name] && <span className={classes.inputCheckbox__error}>{formState.errors[name].message}</span>}
        </label>
    )
}

export default InputCheckbox
