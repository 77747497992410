import { useState, useEffect } from "react";

export default function useWindowSize() {
  const isBrowser = () => typeof window !== "undefined"
  const getSize = () => {
    return {
      width: isBrowser() ? window.innerWidth : null,
      height: isBrowser() ? window.innerHeight : null,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    if (isBrowser()) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener("resize", handleResize)
      }
    };
  }, []);

  return windowSize;
}
