import React, {useState, useRef} from 'react'
import * as classes from './FormAgreement.module.scss'

const FormAgreement = () => {
    const [activeState, setActiveState] = useState(false);
    const [listHeight, setListHeight] = useState('15rem');
    const [agreementButtonState, setAgreementButtonState] = useState({text:'ROZWIŃ', class: classes.formAgreement__agreementButton_close});

    const agreementText = useRef();
    const agreementButton = useRef();

    const toggleHeight = () => {
        setActiveState(activeState ? false : true);
        setListHeight(activeState ? `15rem` : `${agreementText.current.scrollHeight}px`);
        setAgreementButtonState(activeState ? {text:'ROZWIŃ', class: classes.formAgreement__agreementButton_close} : {text:'Zwiń', class: ''});
    }

    return (
        <>
            <ul className={classes.formAgreement__agreementText} ref={agreementText} style={{maxHeight: `${listHeight}`}}>
                <li>
                    1. Klikając przycisk „Wyślij” - wyrażasz zgodę na przetwarzanie przez OEX Cursor S.A. z siedzibą w Warszawie - podanych przez Ciebie w dokumentach aplikacyjnych - danych osobowych dla celów aktualnej rekrutacji (zgoda dotyczy dodatkowych danych osobowych, które nie zostały wskazane w Kodeksie pracy lub w innych przepisach prawa, np. wizerunek, informacje dotyczące zainteresowań, itp.).

                </li>
                <li>
                    2. Administratorem danych osobowych przekazanych przez Ciebie w zgłoszeniu rekrutacyjnym oraz załączonych dokumentach aplikacyjnych - jest OEX Cursor S.A. z siedzibą w Warszawie, pod adresem: ul. Równoległa 4a, 02-235 Warszawa (dalej: „Administrator”).
                </li>
                <li>
                    3. W sprawach związanych z Twoimi danymi osobowymi możesz się kontaktować z - powołanym przez Administratora - Inspektorem Ochrony Danych Osobowych (IODO), za pośrednictwem poczty elektronicznej - e-mail: iodo@cursor.pl lub tradycyjnej: Inspektor Ochrony Danych Osobowych OEX Cursor S.A., Równoległa 4a, 02-235 Warszawa.
                </li>
                <li>
                    4. Twoje dane osobowe Administrator przetwarza w celu przeprowadzenia aktualnego procesu rekrutacyjnego (chyba, że zaznaczyłeś dodatkowo zgodę na przetwarzanie Twoich danych osobowych również do celów przyszłych rekrutacji - wówczas będą one przetwarzane również w tych celach), na następujących podstawach prawnych:
                    <ul className={classes.innerList}>
                        <li className={classes.innerListItem}>
                            dane w zakresie wskazanym w Kodeksie pracy - na podstawie przepisów prawa (ich podanie jest konieczne do    wzięcia udziału w rekrutacji);
                        </li>
                        <li className={classes.innerListItem}>
                            pozostałe dane (np. wizerunek, zainteresowania) - na podstawie Twojej dobrowolnej zgody (ich podanie nie ma wpływu na możliwość udziału w rekrutacji); jeśli nie chcesz aby Administrator przetwarzał dodatkowe informacje na Twój temat, prosimy o nieumieszczanie ich w przesyłanych dokumentach. Równocześnie prosimy o nieumieszczanie w przesłanych dokumentach danych dotyczących np.: stanu cywilnego, liczby posiadanych dzieci, stanu zdrowia, itp. W przypadku ich zamieszczenia Administrator będzie zmuszony do natychmiastowego ich usunięcia z otrzymanych dokumentów
                        </li>
                    </ul>
                </li>
                <li>
                    5. Twoje dane osobowe mogą być ponadto przetwarzane w oparciu o prawnie uzasadniony interes Administratora, tj. w celu ustalenia, dochodzenia lub obrony przed roszczeniami, dotyczącymi prowadzonej rekrutacji.
                </li>
                <li>
                    6. Odbiorcami Twoich danych są: dostawcy systemów informatycznych, dostawcy usług publikacji ogłoszeń o pracę, dostawcy systemów do zarządzania rekrutacjami oraz podmioty świadczące usługi rekrutacyjne, w szczególności Pro People Sp. z o.o. z siedzibą w Warszawie, ul. Równoległa 4a, 02-235 Warszawa, eRecruitment Solutions Sp. z o.o. z siedzibą w Warszawie, ul. Prosta 68, 00-838 Warszawa. Dostęp do Twoich danych osobowych może mieć również klient OEX Cursor S.A., który zlecił OEX Cursor S.A. świadczenie usług, w ramach których, w przypadku zatrudnienia - będziesz wykonywał określone zadania.
                </li>
                <li>
                    7. W przypadku wyrażenia dodatkowej zgody na przetwarzanie Twoich danych również do celów przyszłych rekrutacji - Twoje dane osobowe mogą być przetwarzane przez spółki z grupy OEX.
                </li>
                <li>
                    8. Twoje dane osobowe będą przechowywane przez Administratora przez okres:
                    <ul className={classes.innerList}>
                        <li className={classes.innerListItem}>
                            6 miesięcy od dnia zakończenia rekrutacji - w przypadku zgody wyłącznie na aktualną rekrutację,
                        </li>
                        <li className={classes.innerListItem}>
                            2 lat od dnia przekazania dokumentów aplikacyjnych w przypadku wyrażenia dodatkowej zgody na przyszłe rekrutacje.
                        </li>
                    </ul>
                </li>
                <li>
                    9. Masz prawo do żądania od Administratora dostępu do danych osobowych oraz otrzymania ich kopii, prawo do ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania danych na podstawie uzasadnionego interesu Administratora oraz prawo do przenoszenia swoich danych. W celu realizacji w/w uprawnień skontaktuj się z Inspektorem Ochrony Danych Osobowych, korzystając z jednego z kanałów komunikacyjnych wymienionych w pkt 2.
                </li>
                <li>
                    10. W odniesieniu do danych przetwarzanych przez Administratora na podstawie Twojej zgody (dane inne niż wymienione w Kodeksie pracy), masz prawo cofnąć zgodę w każdym czasie, poprzez złożenie oświadczenia na adres biuro@pro-people.pl. Cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania, dokonanego na podstawie zgody przed jej cofnięciem.
                </li>
                <li>
                    11. W przypadku wyczerpania przysługujących Ci praw względem Administratora i uznania, że przetwarzanie Twoich danych osobowych narusza przepisy dotyczące ochrony danych osobowych, masz prawo wniesienia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa, www.uodo.gov.pl.
                </li>
                <li>
                    12. Nie będziemy podejmować względem Ciebie zautomatyzowanych decyzji (decyzji bez istotnego udziału człowieka), w tym Twoje dane osobowe nie będą podlegały profilowaniu.
                </li>
                <li>
                    13. Administrator nie zamierza przekazywać Twoich danych osobowych do kraju spoza Europejskiego Obszaru Gospodarczego albo organizacji międzynarodowych.
                </li>
            </ul>
            <p className={`${classes.formAgreement__agreementButton} ${agreementButtonState.class}`} ref={agreementButton} onClick={toggleHeight}>{agreementButtonState.text}</p>
        </>
    )
}

export default FormAgreement