import React, { useState, useEffect} from 'react'
import * as classes from './InputText.module.scss'
import { useFormContext } from "react-hook-form";

const InputText = ({name, label, type, requirements, honeypot}) => {
    const [errorState, setErrorState] = useState('')
    const [focusState, setFocusState] = useState('')
    const {register, formState}  = useFormContext()
    const inputType = type ? type : 'string'

    const inputFocus = () => {
        setFocusState(classes.inputText__label_text_focus)
    }
    const inputBlur = (event) => {
        if (!event.target.value ) {
            setFocusState('')
        }
    }
    useEffect(() => {
        formState.errors[name] ? setErrorState(classes.inputText__labelError) : setErrorState('')
    }, [formState.errors[name]])
    return (
        <label className={`${classes.inputText__label} ${errorState} ${honeypot ? classes.inputText__honeypot : '' }`} >
            <input className={classes.inputText__input} type={inputType} {...register(name, {...requirements, onBlur: (event) => inputBlur(event)})} onFocus={inputFocus} />
            {formState.errors[name] && <span className={classes.inputText__error} >{formState.errors[name].message}</span>}
            <p className={`${classes.inputText__label_text} ${focusState}`}>{label}</p>
        </label>
    )
}

export default InputText
