import React, {useState, useEffect, useRef} from 'react'
import * as classes from './InputFile.module.scss'
import { useFormContext } from "react-hook-form"
import { StaticImage } from 'gatsby-plugin-image'

const InputFile = ({name, label, requirements, isCV}) => {
    const {register, formState}  = useFormContext()
    const [errorState, setErrorState] = useState('')
    const labelText = useRef(null)

    const inputChange = (event) => {
        labelText.current.innerText = event.target.value.split(/(\\|\/)/g).pop()
        labelText.current.style.opacity = '1'
    }
    useEffect(() => {
        formState.errors[name] ? setErrorState(classes.inputFile__labelError) : setErrorState('')
    }, [formState.errors[name]])
    return (
        <label className={`${classes.inputFile__label} ${classes.inputFile__label_file} ${errorState} ${isCV || classes.inputFile__disabled }`} >
            <p ref={labelText} className={classes.inputFile__label_text}>{label}</p>
            <StaticImage className={classes.inputFile__icon_file} src="../../images/icon-file.svg" placeholder="blurred" quality={100} alt="File icon" />
            <input style={{display: "none"}} type='file' {...register(name, { ...requirements, onChange: event => inputChange(event)})} accept=".pdf, .doc, .docx, .txt, .odt, .rtf" disabled={!isCV}/>
            {formState.errors[name] && <span className={classes.inputFile__error}>{formState.errors[name].message}</span>}
        </label>
    )
}

export default InputFile
